import * as React from 'react';
import { SVGProps } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={27.168}
      height={27}
      {...props}
    >
      <path
        data-name="Fill 1"
        d="M26.4.786a2.634 2.634 0 0 0-2.622-.68L1.913 6.424a2.6 2.6 0 0 0-1.88 2.054 3.037 3.037 0 0 0 1.362 2.84l6.837 4.175a1.778 1.778 0 0 0 2.187-.26L18.247 7.4a1 1 0 0 1 1.44 0 1.025 1.025 0 0 1 0 1.44l-7.842 7.829a1.78 1.78 0 0 0-.262 2.187l4.177 6.863a2.72 2.72 0 0 0 2.6 1.262 2.642 2.642 0 0 0 2.215-1.875l6.482-21.7A2.64 2.64 0 0 0 26.4.786"
        fill="#ff7746"
      />
      <path
        data-name="Combined Shape"
        d="M6.83 26.593a1.019 1.019 0 0 1 0-1.441l1.855-1.856a1.02 1.02 0 0 1 1.442 1.442L8.27 26.593a1.019 1.019 0 0 1-1.441 0Zm-1.066-5.148a1.019 1.019 0 0 1 0-1.441l1.856-1.856a1.02 1.02 0 1 1 1.442 1.442l-1.857 1.855a1.019 1.019 0 0 1-1.441 0Zm-5.109-1.62a1.019 1.019 0 0 1 0-1.441l1.853-1.854a1.02 1.02 0 0 1 1.447 1.439l-1.861 1.856a1.019 1.019 0 0 1-1.441 0Z"
        fill="#ff7746"
        opacity={0.4}
      />
    </svg>
  );
}

export default SvgComponent;
