export const color = {
  blue: '#0A033C',
  blueLight: '#58687F',
  blueLight2: '#BEBEBE',
  blueAqua: '#D6EBE4',
  white: '#fff',
  white2: '#F9FAFB',
  whiteLight: '#ffffff99',
  iceWhite: '#cfcfcf87',
  black: '#000',
  grayLight: '#B9B9B9',
  gray: '#808080',
  graySemiDark: '#4A4A4A',
  red: '#f00',
  success: '#54D6AB',
  blueBase: '#7FA0C7',
  spotlight: '#FF7746',
  greenLemon: '#C2D654',
  grayBackground: '#F8F8F8',
  grayExtremeLight: '#EFEFEF',

  blueOriginal: '#212F53',
};
