import * as React from 'react';
import { SVGProps } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={42.105}
      height={40}
      {...props}
    >
      <path
        data-name="Fill 1"
        d="m23.108 1.292 4.687 9.42a2.34 2.34 0 0 0 1.765 1.262l10.529 1.533a2.36 2.36 0 0 1 1.55.906 2.279 2.279 0 0 1 .442 1.717 2.341 2.341 0 0 1-.681 1.33l-7.629 7.4a2.223 2.223 0 0 0-.676 2.028l1.878 10.4a2.347 2.347 0 0 1-1.873 2.67 2.46 2.46 0 0 1-1.5-.24l-9.4-4.893a2.461 2.461 0 0 0-2.217 0L10.6 39.718a2.406 2.406 0 0 1-3.226-.942 2.381 2.381 0 0 1-.248-1.476L9 26.9a2.234 2.234 0 0 0-.676-2.031L.7 17.483a2.283 2.283 0 0 1-.061-3.227L.7 14.2a2.269 2.269 0 0 1 1.352-.663L12.579 12a2.345 2.345 0 0 0 1.765-1.264l4.519-9.441A2.33 2.33 0 0 1 21 0h.282a2.349 2.349 0 0 1 1.821 1.291"
        fill="#ff7746"
        opacity={0.4}
      />
      <path
        data-name="Fill 4"
        d="M21.036 34.562a2.582 2.582 0 0 0-1.162.318l-9.346 4.882a2.438 2.438 0 0 1-3.153-.971 2.328 2.328 0 0 1-.244-1.46L9 26.954a2.322 2.322 0 0 0-.677-2.054L.69 17.513a2.3 2.3 0 0 1-.036-3.251.562.562 0 0 1 .036-.034 2.387 2.387 0 0 1 1.331-.668l10.537-1.551a2.279 2.279 0 0 0 1.761-1.264L18.9 1.186A2.327 2.327 0 0 1 21.053 0c-.017.626-.017 34.133-.017 34.559"
        fill="#ff7746"
      />
    </svg>
  );
}

export default SvgComponent;
