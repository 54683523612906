const enUs = {
  copright: '© Cross Border Talents 2022. All rights reserved.',

  error: {
    haveError: 'There was a problem',
  },
  success: {
    titleSuccess: 'Success!',
  },
  info: {
    warning: 'Warning',
  },

  fields: {
    requirements: 'Requirements',
    benefits: 'Benefits',
    recruiter: 'Recruiter',
    teamLeader: 'Team Leader',
    search: 'Search',
    department: 'Department',
    name: 'Name',
    fullName: 'Name',
    lastName: 'Last Name',
    companyName: 'Company Name',
    industry: 'Industry',
    email: 'Email',
    companyEmail: 'Company Email',
    phone: 'Phone',
    status: 'Status',
    country: 'Country',
    countryOfResidence: 'Country of Residence',
    socialMedia: 'Social Media',
    birthDate: 'Birth Date',
    gender: 'Gender',
    nativeLanguage: 'Native Language',
    allowTalentPool: 'Allow Talent Pool',
    privacityPolicy: 'Privacy Policy',
    allowContactMe: 'Allow Contact Me',
    englishLevel: 'English Level',
    title: 'Title',
    level: 'Level',
    description: 'Description',
    sendPicture: 'Send Picture',
    observations: 'Observations',
    aboutCandidate: 'About the candidate',
    testimonial: 'Testimonial',
    date: 'Date',
    webSiteUrl: 'Website URL',
    size: 'Size',
    adressLine1: 'Address Line 1',
    adressLine2: 'Address Line 2',
    city: 'City',
    facebook: 'Facebook',
    instagram: 'Instagram',
    linkedin: 'Linkedin',
    allRegions: 'All Regions',
    typeOfContract: 'Type of Contract',
    experienceLevel: 'Experience Level',
    searchJobTitle: 'Search job by title...',
    language: 'Language',
    yourName: 'Your Name',
    businessEmail: 'Business Email',
    skillsRequired: 'Skills Required',
    residence: 'Residence',
    academyBackground: 'Academy Background',
    multilingual: 'Multilingual',
    typeCategory: 'Type the category, keyword...',
    firstName: 'First Name',
    yourEmail: 'Your Email',
    subject: 'Subject',
    yourMessage: 'Your Message',
    charge: 'Degree',
    profile: 'Profile',
    skillsCompetences: 'Skills & Competences',
    softwares: 'Softwares',
    education: 'Education',
    workExperience: 'Work Experience',
    languages: 'Languages',
    sendToTalentPool: 'Send to Talent Pool',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
    currentPassword: 'Current Password',
    internal: 'Internal',
  },

  page: {
    user: {
      listUser: 'List of admin users',
      newUser: 'New admin user',
      user: 'Admin Users',
      personalInformation: 'Personal information',
    },
    teamLeader: {
      listTeamLeader: 'List of team leaders',
      newTeamLeader: 'New team leader',
      teamLeader: 'Team leader',
    },
    recruiter: {
      listRecruiter: 'List of recruiters',
      newRecruiter: 'New recruiter',
      recruiter: 'Recruiter',
    },
    companies: {
      listCompanies: 'List of companies',
      newCompanies: 'New company',
      companies: 'Company',
      sendLogo: 'Send logo',
      aditionalInfo: 'Aditional information',
      companyInformation: 'Company information',
      contactInformation: 'Contact information',
      socialMedia: 'Social media',
    },
    candidates: {
      listCandidates: 'List of candidates',
      newCandidates: 'New candidate',
      candidates: 'Candidate',
      sendPicture: 'Send picture',
      cvUpload: 'Upload CV',
      personalInformation: 'Personal information',
      professionalInformation: 'Professional information',
      permissions: 'Permissions',
      informationToTalentPool: 'Information to Talent Pool',
    },
    interestSkills: {
      listInterestSkills: 'List of departament',
      newInterestSkills: 'New departament',
      interestSkills: 'Departament',
    },
    jobs: {
      listJobs: 'List of jobs',
      newJobs: 'New job',
      jobs: 'Job',
    },
    testimonials: {
      listTestimonials: 'List of testimonials',
      newTestimonials: 'New testimonial',
      testimonials: 'Testimonial',
    },
    talentPool: {
      listTalentPool: 'List of talent pool',
    },
    filter: 'Filter',
  },

  personalInformation: 'Personal information',
  permissions: 'Permissions',
  cancel: 'Cancel',
  ok: 'Ok',
  informationCandidate: 'Information candidate',
};
export default enUs;
